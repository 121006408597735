
.right-container form#emailForm {
    width: 100%;
}

// .right-container__backToLogin-btn {
//     width: 150px;
//     height: 28px;
//     background-color: transparent;
//     background-image: url("../../assets/images/backArrow.png");
//     border: none;
//     background-position: left center;
//     background-repeat: no-repeat;
//     background-size: 10%;
//     color: #1544E0;
// }
.backToLogin {
    font-size: 1rem;
    line-height: 1.563rem;
    font-family: 'Poppins-Regular';
    color: #575757;
}
.login-text {
    font-size: 1rem;
    line-height: 1.563rem;
    font-family: 'Poppins-Medium';
    color: #1544E0;
    cursor: pointer;
}

.right-container {
    & #accessForm {
        width: 100%;
    }
    & #inputEmail,
    #firstName,
    #inputNumber {
        color: #151515;
        border: 1px solid #919191;
        border-radius: 10px;
        height: 52px;
        &::placeholder{
            color: #51585F;
        }
    }
    /* Chrome, Safari, Edge, Opera */
    & #inputNumber::-webkit-outer-spin-button,
    #inputNumber::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    /* Firefox */
    & input[type=number] {
        -moz-appearance: textfield;
      }
    & #inputMessage {
        color: #151515;
        border: 1px solid #CDCDCD;
        border-radius: 10px;
        &::placeholder{
            color: #51585F;
        }
    }
    & .request-subText {
        display: block;
        font-family: "Poppins-Regular";
        font-size: 0.969rem;
        color: #575757;
        text-align: center;
        line-height: 1.563rem;
    }
    // &__loginForm {
    //     & .request-success {
    //         top: 0em;
    //     }
    // }
}
.request-section {
    & .request-success {
        position: absolute;
    }
}