.reset-password {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    & .reset-notification-container {
        width: 50%;
        position: absolute;
        top: -60px;
        padding: 0;
    }
    & .reset-notification-wrapper {
        background-color: #1115df;
    }
    & .reset-container-box {
        width: 86.5%;
        margin: 0 auto;
        position: relative;
        display: flex;
        flex-grow: 1;
        & .reset-pwd-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
    & .change-password-text {
        color: #151515;
        font-size: 1.625rem;
        font-family: 'Poppins-Bold';
    }
    & .sub-text {
        color: #151515;
        font: normal normal normal 1rem/1.5 Poppins-Regular;
    }
    & .input-field {
        color: #151515;
        border: 1px solid #CDCDCD;
        font: normal normal normal 1rem/1.5 Poppins-Regular;
        border-radius: 10px;
        height: 52px;
        &::-webkit-input-placeholder { /* Edge */
            color: #51585F;
        }
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #51585F;
        }
        &::placeholder {
            color: #51585F;
        }
    }
    & .change-password-btn {
        background-color: #1544E0;
        width: 100%;
        height: 52px;
        color: #fff;
        font: normal normal normal 1rem/1.5 Poppins-Regular;
        &:hover {
            background-color: #0E32AB;
        }
    }
    & .form-field {
        & .togglePassword {
            position: relative;
            & .eye-btn {
                position: absolute;
                width: 25px;
                height: 17.7px;
                right: 1rem;
                border: none;
                background-color: transparent;
                background-repeat: no-repeat;
                top: -2.15rem;
                &.hidden {
                    background-image: url("../../assets/images/hidePassword.svg");
                }
                &.visible {
                    background-image: url("../../assets/images/showPassword.svg");
                }
                &:focus-visible {
                    outline-offset: 2px;
                }
            }
        }
    }
    // Notification container styling
    .Login-error-close-btn {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-image: url("../../assets/images/error_clos_btn.svg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .Toastify__toast {
        &.reset-notification-wrapper {
            color:#9C0000;
            background-color: #FFE0E0;
            border: 1px solid #9C0000;
            border-radius: 7px;
            background-image: url("../../assets/images/noun-warning.png");
            background-repeat: no-repeat;
            background-position-x: 0.5vw;
            background-position-y: 1vw;
            background-size: 5%;
            align-items: center;
            width: 100%;
            display: flex;
            align-items: flex-start;
        }
    }
    .Toastify__toast-body {
        &.reset-notification-body {
            font-family: 'Poppins-Regular';
        }
    }
}