.upload-container {
    flex-grow: 1;

    & .diloag-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 100;
        display: none;

        &.open {
            display: block;
        }
    }

    &__header {
        font-size: 2.625rem;
    }

    & .alt-text-bg {
        background-color: #1544E0;
        color: #fff;
        width: 176px;
        position: relative;
        display: inline-block;
        padding-top: 3px;
        height: 57px;
    }

    & .description-text {
        color: #575757;
        font-size: 1rem;
    }

    & .browse-container {
        background-color: #fff;
        border: 1px solid #CDCDCD;
        border-radius: 6px;
        width: 62%;

        & .inner-container-parent {
            width: 100%;
            border: 2px dashed #CED9FF;
            border-radius: 6px;
            background: url(../../assets/images/upload-bg.png) #FBFCFF;
            height: 100%;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;
        }

        & .inner-container {
            // background: #FBFCFF;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-content: center;
            align-items: center;

            & .data-container {
                padding: 40px 0;
                display: flex;
                width: 65%;
                flex-direction: column;
                justify-content: center;
                flex-wrap: wrap;
                align-content: center;
                align-items: center;
            }

            & .drag-instruction {
                color: #151515;
                font-weight: bold;
                font-family: 'Poppins-Regular';
                font-size: 1rem;
            }

            & .or-txt {
                color: #575757;
                font-family: 'Poppins-Bold';
            }

            & .upload-instruction {
                color: #151515;
                font-family: 'Poppins-Regular';
                font-size: 1rem;
                font-weight: bold;
            }

            & .browse-btn {
                background-color: #1544E0;
                border: none;
                border-radius: 6px;
                color: #fff;
                padding-right: 2.3rem;
                padding-left: 2.3rem;
                padding-top: 0.56rem;
                padding-bottom: 0.56rem;
                &.disabled {
                    pointer-events: none;
                }
                &:hover {
                    background-color: #0E32AB;
                }
                &:focus-visible {
                    outline: none;
                    box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;

            }
            }

            & .supported-files-text {
                color: #575757;
                font-family: 'Poppins-Regular';
                font-size: 1rem;
                // display: flex;
                // flex-direction: column;
                // align-items: center;
                // justify-content: center;
                // row-gap: 0.5rem;
            }

            & .file-limit-text {
                color: #575757;
                font-family: 'Poppins-Regular';
                font-size: 1rem;
            }
            & .note-text {
                color: #000;
                font-family: "Poppins-Regular";
                font-size: 22px;
                font-weight: 600;
                text-align: center;
                background-color: #EBF0FF;
                padding: 10px;
                border-radius: 8px;
            }

            & .file-types {
                font-weight: 600;
            }

            & .file-icons {
                display: flex;
                align-items: center;
                column-gap: 10px;
            }
        }

        & .upload-files-container {
            width: 100%;
            flex-grow: 1;

            & .uploaded-files-txt {
                font-family: 'Poppins-Regular';
                font-size: 1.25rem;
                font-weight: 600;
                color: #151515;
            }

            & .file-status {
                width: 100%;
                border: 1px solid #CDCDCD;
                border-radius: 4px;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                &.hidden {
                    display: none;
                }

                & .file-item {
                    width: 75%;
                    & .file-name {
                        color: #1544E0;
                    }
                    & .progress {
                        width: 50%;
                        height: 0.35rem;
                    }
                    & .error-msg {
                        color: #ee0d50;
                        font-size: 0.8rem;
                    }
                }

                & .thumb-img-container {
                    width: 48px;
                    height: 48px;

                    & img {
                        height: inherit;
                        width: inherit;
                        border-radius: 4px;
                    }
                }

                & .file-size {
                    color: #979797;
                    font-size: 0.8rem;
                }
            }

            & .status-btn {
                border: 0;
                background: none;
                padding: 0;
            }

            & .cancel-btn,
            & .retry-btn {
                color: #1544E0;
                font-size: 1rem;
                font-family: 'Poppins-Regular';

                &.hidden {
                    display: none;
                }
            }

            & .deleteBtn {
                font-family: 'Poppins-Regular';
                color: #ee0d50;
                font-size: 1rem;
            }

            & .upload-percentage {
                position: relative;
                top: -16px;
                float: right;
                width: 49%;
                & .percent-val {
                    color: #1544E0;
                }
            }

            & .progress-bar {
                background-color: #F4B51E;
                &.upload-success {
                    background-color: #19AC0F;
                }
            }

            & .upload-cancel-btn {
                border-radius: 10px;
                background-color: #151515;
                border: none;
                color: #fff;
                padding-right: 3rem;
                padding-left: 3rem;
                padding-top: 0.86rem;
                padding-bottom: 0.86rem;
                &.disabled {
                    pointer-events: none;
                }

                &:hover {
                    background-color: #575757;
                }
            }

            & .upload-file-btn {
                border-radius: 10px;
                background-color: #1544E0;
                border: none;
                color: #fff;
                padding-right: 3rem;
                padding-left: 3rem;
                padding-top: 0.86rem;
                padding-bottom: 0.86rem;

                &:hover {
                    background-color: #0E32AB;
                }
                &.disabled {
                    pointer-events: none;
                }
            }
        }
    }
    & .uploadImageContainer {
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
    }
}

@media screen and (min-width: 1366px) and (max-width: 1366px) {
    // .browse-container {
    //     width: 904px !important;
    // }
}
.uploadContainer-headingTag{
    font-size: 2.625rem;
}