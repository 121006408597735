@font-face {
    font-family: "Poppins-Regular";
    src: url("/src/assets/fonts/Poppins/Poppins-Regular.woff2")
        format("woff2"),
      url("/src/assets/fonts/Poppins/Poppins-Regular.woff") format("woff"),
      url("/src/assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Poppins-Black";
    src: url("/src/assets/fonts/Poppins/Poppins-Black.woff2")
        format("woff2"),
      url("/src/assets/fonts/Poppins/Poppins-Black.woff") format("woff"),
      url("/src/assets/fonts/Poppins/Poppins-Black.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Poppins-Light";
    src: url("/src/assets/fonts/Poppins/Poppins-Light.woff2")
        format("woff2"),
      url("/src/assets/fonts/Poppins/Poppins-Light.woff") format("woff"),
      url("/src/assets/fonts/Poppins/Poppins-Light.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Poppins-Medium";
    src: url("/src/assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Poppins-Bold";
    src: url("/src/assets/fonts/Poppins/Poppins-Bold.woff2")
        format("woff2"),
      url("/src/assets/fonts/Poppins/Poppins-Bold.woff") format("woff"),
      url("/src/assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}