.modalBox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    
    .modal-content {
      background-color: #fff;
      padding: 24px;
      border-radius: 13px;
      max-width: 549px;
      text-align: left;

      .deleteConfIcon {
        width: 45px;
        height: 45px;
        background-repeat: no-repeat !important;
        position: absolute;
        background: url('../../assets/images/delete_conf.svg');
    }
        .delete-header {
            text-align: left;
            font-size: 24px;
            font-family: "Poppins-Bold";
            letter-spacing: 0px;
            color: #151515;
            opacity: 1;
            position: relative;
            left: 60px;
        }
        
        .delete-file-name {
            font-size: 16px;
            font-family: 'Poppins-Bold';
            letter-spacing: 0px;
            color: #151515;
        }
        
        .deleteText {
            padding-left: 60px;
            display: inline-block;
            text-align: left;
            font-size: 16px;
            font-family: 'Poppins-Regular';
            letter-spacing: 0px;
            color: #151515;
        }
    }
    
    .modal-content h2 {
      margin-top: 0;
    }
    
    .modal-content p {
      margin-bottom: 20px;
    }

    .assign-modal-content {
        background-color: #fff;
        border-radius: 13px;
        width: 549px;
        text-align: left;

        .assign-header {
            text-align: left;
            font-size: 20px;
            font-weight: 600;
            font-family: 'Poppins-Regular';
            letter-spacing: 0px;
            color: #151515;
            opacity: 1;
        }
        .assignModal {
            padding: 16px 20px;
            border-bottom: 1px solid #CDCDCD;
        }
        .assignModalBody {
            padding: 16px 20px;

            p {
                text-align: left;
                font-size: 16px;
                font-family: 'Poppins-Regular';
                letter-spacing: 0px;
                color: #575757;
                opacity: 1;
            }
        }
        .recipient {
            width: 509px;
            height: 52px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #CDCDCD;
            border-radius: 10px;
            text-align: left;
            font-size: 16px;
            font-family: 'Poppins-Regular';
            padding: 10px;
            letter-spacing: 0px;
            color: #949494;
            opacity: 1;
        }
        .autocomplete-input {
            .wrapper {
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #CDCDCD;
                border-radius: 10px;
                opacity: 1;
                box-shadow: none;

                &:focus-within {
                    border: 1px solid #1544E0 !important;
                }

                input {
                    text-align: left;
                    font-size: 16px;
                    font-family: 'Poppins-Regular';
                    letter-spacing: 0px;
                    color: #151515;
                    opacity: 1;
                }
            }
        }
        .error-message{
            color: #dc3545;
            font-size: 14px;
            margin-bottom: 1rem;
        }
    }
      
    .assign-modal-content h2 {
        margin-top: 0;
    }
    
    .assign-modal-content p {
        margin-bottom: 20px;
    }

    .assign-modal-buttons {
        padding: 0 14px 20px;
    }
    .modal-buttons, .assign-modal-buttons {
      display: flex;
      justify-content: right;

      .cancel-button {
        background-color: #151515;
        border-radius: 10px;
        width: 146px;
        height: 52px;
        text-align: center;
        font-size: 16px;
        font-family: 'Poppins-Regular';
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
      }
      .confirm-button {
        background-color: #1544E0;
        border-radius: 10px;
        width: 146px;
        height: 52px;
        text-align: center;
        font-size: 16px;
        font-family: 'Poppins-Regular';
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        border: none;

        &:active {
            background-color: #0E32AB;
        }
      }
      .disable-button {
        background-color: #61a1fe;
        border-radius: 10px;
        width: 146px;
        height: 52px;
        text-align: center;
        font-size: 16px;
        font-family: 'Poppins-Regular';
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        border: none;
      }
    }
    
    .modal-buttons button, .assign-modal-buttons button {
      margin: 0 5px;
    }
}
