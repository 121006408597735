body {
    overflow-y: auto;
}
.dataTable {
    .delete-btn, .export-btn, .import-btn, .assign-btn, .refresh-btn {
        position: relative;
        font-size: 16px !important;
        font-family: 'Poppins-Regular' !important;
        letter-spacing: 0px !important;
        color: #1544E0 !important;
        opacity: 1;
        background: transparent !important;
        border: none !important;
        width: 80px;
        text-align: end !important;
        margin: 0 0.7vw 0;

        &:active {
            opacity: 0.7 !important;
        }
        &:focus {
            outline: none;
            box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;
        }
    }
    .refresh-btn{
        width: 94px;
        &:focus {
            outline: none;
            box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;
        }
        & .refreshIcon{
            top : 3px;
        }
    }
    .export-btn > a {
        text-decoration: none;
        color: #1544E0 !important;
        &:focus {
            outline: none;
            box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;
        }
    }
    .importIcon {
        left: -3.4px !important;
    }
    .trashIcon, .exportIcon, .importIcon, .assignIcon, .languageIcon, .refreshIcon {
        width: 20px;
        height: 20px;
        background-repeat: no-repeat !important;
        position: absolute;
        left: 0;
        top: 6px;
        &:focus {
            outline: none;
            box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;
        }
    }
    .refreshIcon {
        background-image: url("../../assets/images/refresh.svg") !important;
    }
    .trashIcon {
        background-image: url("../../assets/images/delete.svg") !important;
    }
    .exportIcon {
        background-image: url("../../assets/images/export.svg") !important;
    }
    .importIcon {
        background-image: url("../../assets/images/import.svg") !important;
    }
    .assignIcon {
        background-image: url("../../assets/images/assign.svg") !important;
    }

    .thumbnail-image {
        pointer-events: none;
        cursor: default;
    }
    // div[data-column-id="1"] {
    //     &.rdt_TableCol_Sortable {
    //         // visibility: hidden;
    //         opacity: 0;
    //     }
    //     &.rdt_TableCell {
    //       //  visibility: hidden;
    //     }
    // }
    .rdt_TableCol_Sortable {
        text-align: left;
        letter-spacing: 0px;
        color: #151515;
        opacity: 1;
        font-size: 16px;
        font-family: 'Poppins-Bold';
        &:focus-visible {
            outline: none;
            box-shadow: 0 0 0 0.15rem rgba(13, 110, 253, 0.86) !important;
          }

        .sc-kpDqfm {
            content-visibility: hidden;
        }
    }
    .rdt_TableCol {
        justify-content: flex-start;
        padding-left: 16px;
        padding-top: 3px;
        padding-bottom: 3px;

        & input[type="checkbox"] {
            margin-left: -2px;
            width: 20px;
            height: 20px;
            &:focus {
                outline: none;
                box-shadow: 0 0 0 0.15rem rgba(13, 110, 253, 0.86) !important;
            }
        }
    }
    .rdt_TableHeader, .rdt_TableRow {
        background-color: #FBFCFF;
        .custom-title {
            text-align: left;
            font-size: 20px;
            font-family: 'Poppins-Regular';
            font-weight: 600;
            letter-spacing: 0px;
            color: #151515;
            opacity: 1;
            cursor: default;
        }
    }
    .rdt_TableRow {
        color: #151515;
        font-size: 16px;
        font-family: 'Poppins-Regular';
        padding: 8px 0;
        cursor: pointer;
        margin-bottom: 3px;
        margin-top: 3px;
        margin-right: 2px;
        width: 99%;
        margin-left: 3px;
        // border: 1px solid transparent;
        &:focus-visible {
            outline: none;
            box-shadow: 0 0 0 0.15rem rgba(13, 110, 253, 0.76) !important;
        }
        &:hover {
            background: #EEF2FF;
            box-shadow: 0 0 0 0.15rem rgba(13, 110, 253, 0.76) !important;
        }

        & .thumbWrapper {
            display: flex;
            flex-direction: column;
            text-align: center;
            & .thumbnail-wrapper {
                background: #fff;
                border-radius: 12px;
                display: flex;
                height: 8rem;
                justify-content: center;
                max-height: 100%;
                max-width: 100%;
                overflow: hidden;
                position: relative;
                width: 100%;
    
                & .thumbnail-container {
                    align-items: center;
                    display: flex;
    
                    & .thumbnail-image {
                        max-width: 100%;
                        max-height: 100%;
                        object-fit: contain;
                    }
                }
            }
            & .image-fileName, .childCount {
                font-family: 'Poppins-Regular';
                letter-spacing: 0px;
                color: #151515;
                opacity: 1;
                margin-top: 10px;
            }
            .image-fileName {
                font-size: 13px;
            }
            .childCount {
                font-size: 12px;
            }
        }
        & .rdt_TableCell {
            & img {
                border-radius: 0.2vw;
            }
            & input[type="checkbox"] {
                width: 20px;
                height: 20px;
                &:focus {
                    outline: none;
                    box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;
                }
            }
        }
        & .failed-error {
            display: flex;
            align-items: center;
            padding: 10px;
            border-radius: 5px; /* Rounded corners */
            & .failed-error-icon {
                width: 25px;
                height: 25px;
                margin-left: 5px;
                background-repeat: no-repeat;
                position: absolute;
                background-image: url("../../assets/images/failed-error-icon.svg");
                background-size: cover;
            }
            & .failed-text {
                margin-left: 36px;
                font-size: 16px;
                font-family: 'Poppins-Regular';
                letter-spacing: 0px;
                color: #9C0000;
                opacity: 1;
            }
        }
    }
    .rdt_TableCell a {
        color: #151515;
        text-decoration: none;
    }
    
    .fileStatus {
        background: #EEF2FF 0% 0% no-repeat padding-box;
        border: 1px solid #1544E0;
        border-radius: 23px;
        opacity: 1;
        text-align: center;
        font-size: 16px;
        font-family: 'Poppins-Regular';
        letter-spacing: 0px;
        color: #151515;
        width: 120px;
        height: 39px;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        cursor: default;

        &.pending-btn {
            background: #EEEEEE 0% 0% no-repeat padding-box;
            border: 1px solid #151515;
            margin: 10px 0;
        }
        &.progress-btn {
            background: #FFF5D8 0% 0% no-repeat padding-box;
            border: 1px solid #F5B607;
            margin: 10px 0;
        }
        &.draft-btn {
            &:hover {
                opacity: 1;
            }
            &:active {
                opacity: 0.7;
            }
        }
        &.reviewed-btn {
            background: #E7FFFB 0% 0% no-repeat padding-box;
            border: 1px solid #95EEDE;

            &:hover {
                opacity: 1;
            }
            &:active {
                opacity: 0.7;
            }
        }
        &.failed-btn {
            background: #FFE0E0 0% 0% no-repeat padding-box;
            border: 1px solid #EE0D50;
            margin: 10px 0;
        }
    }
    & .noDataComponent {
        padding: 24px;
    }
    .rdt_Pagination {
        .DfDUN, .gRhxQD {
            color: rgb(81, 88, 95);
            fill: rgb(81, 88, 95);
            &:disabled {
                color: rgb(145, 145, 145);
                fill: rgb(145, 145, 145);
            }
        }
        .isLfJH, .svYMe, .irLOhX, .gPzcGm {
            color: #51585F;
        }
    }
    .dataTable-date-column:focus-visible {
        outline: none;
        box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;

    }
}
.dataTable {
    .sc-feUZmu {
        overflow: auto;
    }
    .rdt_TableBody {
        background: #FBFCFF
    }
}
.dataTable, .altTextDetails {
    background: #FBFCFF 0% 0% no-repeat padding-box;
    // border: 1px solid #CDCDCD;
    border-radius: 10px;
    opacity: 1;
    padding: 0vw 1vw 0 1vw;
    font-size: 16px !important;
    font-family: 'Poppins-Regular' !important;
}
.dashboardPage {
    flex-grow: 1;
    .dashboardTitle {
        text-align: left;
        font-size: 1.757vw;
        font-family: 'Poppins-Bold';
        letter-spacing: 0px;
        color: #151515;
        opacity: 1;
    }
}
.altTextDetails {
    background: #FFFFFF 0% 0% no-repeat padding-box;

    .generatedImage {
        border-radius: 0.4vw;
        // width: 100%;
    }
    /* Add these styles to your existing CSS file or create a new one */
    .image-wrapper {
        background: #F2F2F4;
        // height: 110vh;
    }
    .image-preview-wrapper {
        position: relative;
        width: 100%;
        height: 20vw;
        display: flex;
        justify-content: center;
        /* border: 1px solid; */
        background: white;
        border-radius: 12px;
        max-width: 100%; /* Adjust this value to control the container's maximum width */
        max-height: 100%; /* Adjust this value to control the container's maximum height */
        overflow: hidden;

        .magnify-image {
            width: 23px;
            height: 23px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            position: absolute;
            right: 15px;
            top: 15px;
            background-image: url("../../assets/images/zoom-icon.svg");
            cursor: pointer;
        }
        .image-container {
            display: flex;
            align-items: center;

            & .generatedImage {
                max-width: 100%; /* Ensure the image fits inside the container */
                max-height: 100%; /* Ensure the image fits inside the container */
                object-fit: contain; /* Maintain aspect ratio and fit within the container */
              }
        }
      }
      
      .image-preview {
        position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 1;
    cursor: pointer;
      }
      
      .image-preview .preview-image {
        width: 100%;
        height: 100%;
        border-radius: 0.4vw;
        align-items: center;
        background: #000000;
        opacity: 0.6;
        display: grid;
        justify-items: center;
        position: absolute;
    }
.preview-text {
    color: white;
    width: 100%;
    text-align: center;
    position: absolute;
    height: 100%;
    display: grid;
    justify-items: center;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: 'Poppins-Regular';
}

.full-screen-icon {
    margin-right: 83px;
    margin-top: 3px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat !important;
    position: absolute;
    background-image: url("../../assets/images/full-screen.png");
}      
      .image-preview-wrapper:hover .image-preview {
        display: block;
      }

      .alt-text-warapper {
        background-color: #FBFCFF;
        border: 1px solid #cdcdcd;
        border-radius: 10px;
        border-bottom-left-radius: unset;
        border-top-left-radius: unset;

        & .altTextContainer_1 {
            user-select: text;
        }
        & .count-container {
            font-size: 14px;
            font-family: 'Poppins-Medium';
            letter-spacing: 0px;
            opacity: 1;
        }
        .accordion-item {
            margin-top: 15px;
            box-shadow: 0px 3px 10px #0000000D;
            border: 1px solid #DEDEDE;
            border-radius: 6px;
            padding: 1px;

            .accordion-header {
                .accordion-button {
                    padding-top: 0;
                    padding-bottom: 0;
                    height: 52px;
                    &:focus {
                        outline: none;
                        box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;
                    }
                }
                .alt-text-header-button, .source-pdf-button {
                    &:not(.collapsed) {
                        color: #fff;
                        background: #151515;
                        border-radius: 6px 6px 0px 0px;
                        opacity: 1;
                    }
    
                    &::after {
                        background-image: url("../../assets/images/Accordion-Arrow-down.svg");
                        width: 20px;
                        height: 20px;
                        background-repeat: no-repeat;
                        position: absolute;
                        right: 15px;
                        top: 15px;
                    }
                    &:not(.collapsed)::after {
                        background-image: url("../../assets/images/Accordion-Arrow-up.svg");
                        transform: none;
                    }
                }
            }
            .pdf-body {
                padding: 15px 0;
                .container {
                    padding: 0;
                    
                    .pdf-container {
                        width: 100%;
                        height: 30rem;
                        user-select: text;
                        overflow-y: auto;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
    
                        /* Add this CSS in your stylesheet */
                        .rpv-toolbar__right {
                            display: none;
                        }
                        .rpv-page-navigation__current-page-input {
                            input {
                                height: 1.8rem !important;
                            }
                        }
                        .zoomInput {
                            .rpv-zoom__popover-target {
                                background-color: #CDCDCD;
                                padding: 1px 2px;
                            }
                        }
    
                    }
                }
            }
        }

        .alt-text-title {
            text-align: left;
            font-size: 20px;
            font-weight: 600;
            font-family: 'Poppins-Regular';
            letter-spacing: 0px;
            opacity: 1;
        }

        & .math-loader-container {
            display: flex;
            width: 100%;
            height: 200px;
            & .math-progress-loader {
                display: flex;
                justify-content: center;
                margin: 30px auto 0;
                width: 40px;
                height: 40px;
                background-image: url("../../assets/images/es-loader.gif");
                background-size: contain;
                background-repeat: no-repeat;
                & .loading-altText {
                    margin-top: 90px;
                    position: absolute;
                }
            }
        }
        & .is-math-container {
            & .is-math-altText {
                text-align: left;
                font-size: 15px;
                font-style: italic;
                font-family: 'Poppins-Regular';
                font-weight: 600;
                letter-spacing: 0px;
                color: #151515;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
      }

    .tab-component {
        & .tab-header {
            border-bottom: 1px solid #cdcdcd;

            & .tab-button {
                margin-right: 15px;
                font-size: 16px;
                font-weight: 600;
                background: transparent;
                border: none;
                outline: none;
                padding: 10px 20px;
                cursor: pointer;
                color: #4D5154;
                transition: 0.3s ease-in-out;
                border-bottom: 3px solid transparent;
                
                &.active {
                    border-bottom: 3px solid #000000;
                    color: #151515;
                }
                &:focus {
                    outline: none;
                    box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;
                }
            }
        }
        & .tab-content {
            overflow: auto;
            min-height: 30vh;
            height: 47vh;

        }
        ::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }
        
        /* Track */
        ::-webkit-scrollbar-track {
        background: #E0E0E0; 
        }
        
        /* Handle */
        ::-webkit-scrollbar-thumb {
        background: #ACACAC;
        border-radius: 3px;
        }
        
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
        background: #555; 
        }
        & .content-data {
            border: 1px solid #CDCDCD;
            padding: 1vw;
            background: white;
            padding-right: 0.6vw;
            padding-bottom: unset;
            padding-left: 0.6vw;
            textarea {
                height: 140px;
                resize: none;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: none;
                border-radius: 4px;
                opacity: 1;
                font-family: 'Poppins-Regular';
                margin-bottom: 5px;
                &:focus-visible {
                    outline: none;
                    box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;
                }
                &::-webkit-input-placeholder {
                    opacity: 1;
                    color: #51585F;
                }
                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: #51585F;
                }
                &::placeholder {
                    color: #51585F;
                }
                &:focus {
                    box-shadow: none;
                }
                &.math-model.disabled {
                    pointer-events: none;
                    opacity: 0.5;
                }
            }
            .regenerate-block {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                background: white;
                position: relative;
                height: 30px;
                align-items: center;

                .regenerate-btn {
                    text-align: center;
                    font-size: 16px;
                    font-family: 'Poppins-Regular';
                    letter-spacing: 0px;
                    color: #1544E0;
                    opacity: 1;
                    background: transparent;
                    border: none;
                    outline: none;
                    position: absolute;
                    right: 0;

                    &.disabled {
                        opacity: 1;
                        color: #51585F;
                        pointer-events: none;
                    }

                    &:active {
                        opacity: 0.7 !important;
                    }
                    &:focus {
                        outline: none;
                        box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;
                    }
                }
            }
        }
        & .metadata-info {
            // border: 1px solid #ccc;
            padding: 20px;
            // border-radius: 5px;
            // background-color: #f9f9f9;
            // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            // max-width: 300px;

            .metadata-table {
                user-select: text;
                .keyPara {
                    width: 14vw;
                    height: 2.5vw;
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    color: #333;
                }
                .valuePara {
                    color: #333;
                }
            }
        }
    }
}
.modal {
    position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust the alpha value to control the blur intensity */
  z-index: 100;
}
.dropdown-status, .language-dropdown {
    .dropdown-toggle {
        border: 1px solid #CDCDCD;
        background-color: #FBFCFF !important;
        border-radius: 4px;
        opacity: 1;
        width: 100%;

        .selected-status {
            text-align: left;
            font-size: 16px;
            font-family: 'Poppins-Regular';
            letter-spacing: 0px;
            color: #151515;
        }
        &:focus-visible,
        &:active{
            border-color: #86b7fe !important;
            outline: 0;
            box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;
        
        }
        &:focus:not(:focus-visible) {
            border-color: #86b7fe !important;
            outline: 0;
            box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;
        }
    }
    .dropdown-toggle::after {
        background-image: url("../../assets/images/dropdown.svg");
        width: 20px;
        height: 14px;
        background-repeat: no-repeat;
        position: relative;
        top: 0.4vw;
        display: inline-block !important;
    }
}
.dropdown-item.active {
    position: relative;
    background-color: #EEF2FF !important;
    color: #151515 !important;
}
.dropdown-item .tick-mark {
    background-image: url("../../assets/images/selectedTick.svg");
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    position: absolute;
    display: inline-block !important;
    right: 10px;
}
.dropdown-item:hover {
    background-color: #EEF2FF !important;
}
.status {
    color: #575757 !important;
    font-size: 16px !important;
    font-family: 'Poppins-Regular' !important;
}
.all {
    color: #151515 !important;
    font-size: 16px !important;
    font-family: 'Poppins-Regular' !important;
}
.searchText {
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: end;
    justify-content: flex-end;

    .search, .statusDD, .datePick {
        display: contents;
    }
    .dropdown-status {
        margin: 0 0.8vw;
        min-width: 215px;
        & .dropdown-toggle {
            padding: 0.875rem 1.25rem 0.875rem 0.75rem;
            color: #151515;
            border: 1px solid #949494;
            text-align: left;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 5px;
            &::after {
                top: auto;
                color: transparent;
            }
            & .selected-status {
                flex-grow: 1;
            }
        }
    }
    .search {
        .form-control {
            text-align: left;
            font: normal normal normal 16px/1.5 Poppins-Regular;
            color: #151515;
            opacity: 1;
            padding: 0.875rem 1rem;

            &::-webkit-input-placeholder { /* Edge */
                color: #51585F;
            }
            
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #51585F;
            }
            
            &::placeholder {
                color: #51585F;
            }
        }
        .inputIcon {
            padding-right: 2.3rem;
            background: url("../../assets/images/search.svg") #FBFCFF no-repeat 95% center;
            background-size: 20px;
            min-width: 288px;
            border: 1px solid #949494;
        &::placeholder {
          color: #51585F; 
        }
        &:focus {
            outline: none;
            box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;
        }
        }
    }
    
    .form-control {
        width: auto;
    }
}
.formControlTextArea {
    .custom-text-block {
        padding: 0.5vw;
        background: white;
        padding-right: 0.6vw;
        padding-left: 0.6vw;
        background: #FBFCFF 0% 0% no-repeat padding-box;
        border: 1px solid #CDCDCD;
        border-radius: 4px;
    }
    .form-control {
        background: transparent;
        border: none;
        border-radius: unset;
        opacity: 1;
        font-size: 16px;
        font-family: 'Poppins-Regular';
        padding-left: 0.4vw;
        &:focus {
            outline: none;
            box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;
        }
    }
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
    background: #E0E0E0; 
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: #ACACAC;
    border-radius: 3px;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background: #555; 
    }
}
.react-datepicker {
    &__calendar-icon {
        padding: 0 !important;
        margin: 11px 12px 0px;
        right: 0;
    }
}
.react-datepicker-popper[data-placement^=bottom] {
    z-index: 99999;
}
.backButton {
    position: relative;
    width: fit-content !important;
    font-size: 1.757vw !important;
    font-family: 'Poppins-Bold' !important;
    letter-spacing: 0px !important;
    color: #151515 !important;
    opacity: 1;
    background: transparent !important;
    border: none !important;
    // width: 6vw;
    text-align: end !important;
    margin: 0 0.7vw 0;
    display: flex !important;
    align-items: center;
    column-gap: 10px;
    &:focus {
        outline: none;
        box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;
    }
}
.dashboardPath {
    position: relative;
    display: inline-block !important;
    width: auto !important;
    background: transparent !important;
    border: none !important;
    padding: unset !important;

    .dashboardLinkText {
        text-align: left;
        font-size: 14px;
        font-family: 'Poppins-Regular';
        letter-spacing: 0px;
        color: #1544E0;
    }
    &:focus {
        outline: none;
        box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;
    }
}
.backArrow {
    width: 20px;
    height: 20px;
    display: inline-block;
    // padding-left: 23px;
    background-repeat: no-repeat !important;
    /* position: absolute;
    left: -1.2vw;
    top: 1.2vw; */
}
.backArrow {
    background-image: url("../../assets/images/backArrow.svg") !important;
    // background-position-y: 9px;
    background-position: center center;
}
.slashPath {
    text-align: left;
    font-size: 14px;
    font-family: 'Poppins-Regular';
    letter-spacing: 0px;
    color: #575757;
    display: inline-block;
}
.fileName, .assignedTo, .alt-text-status {
    text-align: right;
    font-size: 14px;
    font-family: 'Poppins-Regular';
    letter-spacing: 0px;
    color: #575757;
    opacity: 1;
    overflow-wrap: anywhere;
}
.file {
    text-align: left;
    font-size: 20px;
    font-family: 'Poppins-Regular';
    font-weight: 600;
    letter-spacing: 0px;
    color: #575757;
    opacity: 1;
    display: block;
}
.email {
    text-align: left;
    font-size: 16px;
    font-family: 'Poppins-Regular';
    letter-spacing: 0px;
    color: #1544E0;
    opacity: 1;
    display: block;
}
.engineOptions {
    display: flex;
    align-items: center;

    & .engineName {
        display: flex;
        align-items: center;
        border-radius: 5px; /* Rounded corners */
        & .engine-icon {
            width: 14px;
            height: 14px;
            margin-left: 5px;
            margin-bottom: 3px;
            background-repeat: no-repeat;
            position: absolute;
            background-image: url("../../assets/images/engineIcon.svg");
            background-size: cover;
        }
        & .failed-text {
            margin-left: 36px;
            font-size: 16px;
            font-family: 'Poppins-Regular';
            letter-spacing: 0px;
            color: #EE0D50;
            opacity: 1;
        }
    }
    /* Position tooltip above the element */
  [data-tooltip]:before {
    position: absolute;

    background: #E4E4E4 !important;
    color: #151515 !important;
    border: none;
    width: max-content;
    min-width: auto;

    bottom: 150%;
    left: 0%;
    margin-bottom: -13px;
    font-size: 13px;
    font-family: 'Poppins-Regular';
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: 25px;
    padding: 3px;
    height: auto;
    background-color: #000;
    background-color: #151515;
    content: attr(data-tooltip);
    text-align: center;
    line-height: 1.5;
    border-radius: 6px;
  }
}
.engineName::before {
    border: 1px solid;
    width: auto;
    padding: 0 30px;
    font-size: 14px;
    font-family: "Poppins-Regular";
    background: palegreen;
}
.altText_1 {
    display: flex;
    text-align: left;
    font-size: 14px;
    font-family: 'Poppins-Regular';
    font-weight: 600;
    letter-spacing: 0px;
    color: #504B5A;
    opacity: 1;

    &.mathOption {
        color: #424242;
    }
}
.altText_textArea {
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 13px;
    font-weight: 600;
    font-family: 'Poppins-Regular';
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;

    .human-icon {
        width: 32px;
        height: 20px;
        background-position: center center;
        background-repeat: no-repeat;
        right: 5px;
        background-image: url("../../assets/images/human-icon.svg");
    }
 }
 .alt-text-1 {
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    // justify-content: space-between;
    // align-items: center;
    &:hover {
        background: #D9EDFF 0% 0% no-repeat padding-box;
        border-radius: 5px;
    }
    &.active {
        background: #D9EDFF 0% 0% no-repeat padding-box;
        border: 1px solid #2B97FA;
        border-radius: 5px;
    }
    &.none-of-above-option {
        & .form-control-details {
            font-size: 14px;
            font-style: unset;
            font-weight: 600;
            color: #504B5A;
        }
    }
}
.mathModel {
    cursor: default;
    &.active {
        background: #D9EDFF 0% 0% no-repeat padding-box;
        border: 1px solid #2B97FA;
        pointer-events: auto;
        &:hover {
            background: #D9EDFF 0% 0% no-repeat padding-box;
            border-radius: 5px;
        }
    }
    &:hover {
        background: none;
        border-radius: none;
    } 
}
.limit-reached {
    display: flex;
    flex-direction: column;
    background-color: #F2F2F4;
    border-radius: 4px;

    & .limit-error-details {
        border-radius: 8px;
        padding: 1vw;
        height: auto;
        min-height: 55px;
        overflow: auto;
        opacity: 1;
        text-align: left;
        font-size: 12px;
        font-style: italic;
        font-family: 'Poppins-Regular';
        letter-spacing: 0px;
        color: #151515;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.updating-alt-text {
    opacity: 1;
    text-align: left;
    font-size: 16px;
    font-family: 'Poppins-Regular';
    letter-spacing: 0px;
    color: #0F3DD6;
}
.form {
    & .feedbackContainer {
        display: flex;
        justify-content: flex-end;
        column-gap: 8px;
        padding-right: 1rem;
        & .feedback-up {
            display: block;
            // position: absolute;
            // right: 42px;
            width: 20px;
            height: 20px;
            background-image: url("../../assets/images/thumbs-up.svg");
            background-repeat: no-repeat;
            background-size: cover;
            &.active {
                background-image: url("../../assets/images/thumbs-up-active.svg");
            }
        }
        & .feedback-down {
            display: block;
            // position: absolute;
            // right: 14px;
            width: 20px;
            height: 20px;
            background-image: url("../../assets/images/thumbs-down.svg");
            background-repeat: no-repeat;
            background-size: cover;
            &.active {
                background-image: url("../../assets/images/thumbs-down-active.svg");
            }
        }
    }
    & .removeFeedback {
        display: none;
    }
    .form-control-details {
        // background: #EEF2FF 0% 0% no-repeat padding-box;
        border-radius: 8px;
        padding: 0.1vw 6vw 1vw 1vw;
        height: auto;
        min-height: 55px;
        overflow: auto;
        opacity: 1;
        text-align: left;
        font-size: 16px;
        font-style: italic;
        font-family: 'Poppins-Regular';
        letter-spacing: 0px;
        color: #151515;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow-wrap: anywhere;

        &.none-of-above {
            min-height: auto;
            padding: 0;
        }
        & .read-more-btn {
            cursor: pointer;
            display: contents;
            color: blue;
        }
        & .none-of-above-text {
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            color: #504B5A;
        }
     }

     & .form-check-inline {
        position: absolute;
        right: 0;
        margin-right: 0px !important;
        margin-top: -6px !important;
     }
    .form-check-input {
        display: none;
    }
    &__radio-button {
        // width: 1.5em;
        // height: 1.5em;
        // border: 1px solid #1544E0;
        // border-radius: 50%;
        display: inline-block;
        position: relative;
        left: 0;
        top: 0;
        cursor: pointer;
        &::after {
            content: "";
            display: block;
            height: 17px;
            width: 17px;
            background: linear-gradient(to left bottom, blue 50%, transparent 50%);
            padding: 0;
            font-size: 1.5em;
            text-align: center;
            margin: 0.25em 0;
            color: #ffffff;
            opacity: 0;
            transition: opacity .3s;
            border-top-right-radius: 3px;
        }
        // &::after {
        //     content: "";
        //     display: block;
        //     height: 26px;
        //     width: 30px;
        //     // border-radius: 50%;
        //     position: absolute;
        //     top: 50%;
        //     left: 50%;
        //     transform: translate(-50%, -50%);
        //     // background-color: #1544E0;
        //     background-image: url("../../assets/images/tick-mark.svg");
        //     background-repeat: no-repeat;
        //     opacity: 0;
        //     transition: opacity .5s;
        // }
    }
    &__radio-label {
        display: flex;
    }
    &__radio-input:checked + &__radio-label &__radio-button::after {
        opacity: 1;
    }
 }
.selectText {
    display: flex;
    text-align: left;
    font-size: 16px;
    font-family: 'Poppins-Regular';
    letter-spacing: 0px;
    color: #575757;
    opacity: 1;
}
.languageOptions {
    display: flex !important;
    justify-content: space-between;
    width: 24.305vw;
}
.options-menu {
    width: 24.305vw;
}
.languages {
    text-align: left;
    font-size: 16px;
    font-family: 'Poppins-Regular';
    letter-spacing: 0px;
    color: #151515;
    opacity: 1;

    .languageIcon {
        position: relative;
        display: inline-block;
        background-image: url("../../assets/images/language.png") !important;
    }
}
.saveGroup {
    display: flex;
    justify-content: flex-end;
    .save-button {
        width: 165px;
        height: 52px;
        display: flex !important;
        position: relative;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        margin-bottom: 5vw;
        &:focus {
            outline: none;
            box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;
        }
    
        .saveIcon {
            width: 20px;
            height: 20px;
            background-image: url("../../assets/images/loader.svg");
            background-repeat: no-repeat !important;
            margin: 0 10px 0 0px;
        }
    }
}
.form-group {
    .form-control {
        resize: none;
        height: 105px;
    }
}

.datePick {
    .react-datepicker {
        &__calendar-icon {
            display: none;
        }
        &__close-icon {
            position: absolute;
            top: 9px;
            height: 60%;
            right: 50px;

            &::after {
                background-color: #1544E0;
                height: 20px;
                width: 18px;
                font-size: 14px;
                vertical-align: baseline;
            }
        }
    }
    .datePicker {
        border-radius: 4px;
        opacity: 1;
        font-size: 1rem;
        line-height: 1.5;
        font-family: 'Poppins-Regular';
        background: url("../../assets/images/date.svg") #FBFCFF no-repeat 95% center;
        background-size: 20px;
        padding: 0.875rem 2.25rem 0.875rem 0.75rem;
        cursor: pointer;
        color: #151515;
        border: 1px solid #949494;
        &:focus{
            border-color: #86b7fe;
            outline: 0;
            box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;
        }
        &::-webkit-input-placeholder { /* Edge */
            color: #51585F;
        }
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #51585F;
        }
        &::placeholder {
            color: #51585F;
        }
     }
}

.dashboard-upload-container {
    min-height: 430px;

    .upload-inner-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .noFiles-instruction {
            text-align: center;
            font-size: 24px;
            font-family: 'Poppins-Bold';
            letter-spacing: 0px;
            color: #575757;
            opacity: 1;
        }
        .files-upload-instruction {
            text-align: center;
            font-size: 16px;
            font-family: 'Poppins-Regular';
            letter-spacing: 0px;
            color: #575757;
            opacity: 1;
        }
        .upload-btn {
            background-color: #1544E0;
            border-radius: 6px;
            text-align: center;
            font-size: 16px;
            font-family: 'Poppins-Regular';
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
            border: none;
            height: 42px;
            padding: 0;
            width: 124px;
        }
    }
}
.detailsContainer {
    height: -webkit-fill-available;
    position: relative;
}
.imageDetails  {
    .navigation {
        display: flex;
        justify-content: flex-end;

       
        .next-btn {
            color: #0238E0;
            position: relative;
            font-size: 16px !important;
            font-family: 'Poppins-Regular' !important;
            letter-spacing: 0px !important;
            opacity: 1 !important;
            background: transparent !important;
            border: none !important;
            width: 60px;
            text-align: end !important;
            right: 10px;
            padding-right: 18px;

            &:active {
                opacity: 0.7 !important;
            }
            &:focus {
                outline: none;
                box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;
            }
        }
        .prev-btn {
            color: #151515;
            position: relative;
            font-size: 16px !important;
            font-family: 'Poppins-Regular' !important;
            letter-spacing: 0px !important;
            opacity: 1 !important;
            background: transparent !important;
            border: none !important;
            width: 60px;
            text-align: end !important;
            right: 10px;
            padding-right: 10px;

            &:active {
                opacity: 0.7 !important;
            }
            &:focus {
                outline: none;
                box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;
            }
        }
        .next-btn {
            &.disableNext {
                opacity: 0.5 !important;
                // color: #4D5154;
                pointer-events: none;
                &:focus,:focus-visible{
                    box-shadow: none;
                    outline: none;
                }
                // .nextIcon {
                //     width: 20px;
                //     height: 20px;
                //     background-repeat: no-repeat !important;
                //     position: absolute;
                //     left: 50px;
                //     top: 11px;
                //     background-image: url("../../assets/images/Next-disabled.svg");
                // }
            }
        }
        .prev-btn {
            &.disablePrev {
                opacity: 0.5 !important;
                // color: #4D5154;
                pointer-events: none;
                &:focus,:focus-visible{
                    box-shadow: none;
                    outline: none;
                }
                .prevIcon {
                    width: 20px;
                    height: 20px;
                    background-repeat: no-repeat !important;
                    position: absolute;
                    left: 0;
                    top: 11px;
                    background-image: url("../../assets/images/Previous-disabled.svg");
                }
            }
        }
        .prevIcon {
            width: 20px;
            height: 20px;
            background-repeat: no-repeat !important;
            position: absolute;
            left: 0;
            top: 11px;
            background-image: url("../../assets/images/prev.svg");
        }
        .nextIcon {
            width: 20px;
            height: 20px;
            background-repeat: no-repeat !important;
            position: absolute;
            left: 50px;
            top: 11px;
            background-image: url("../../assets/images/Next-enabled.svg");
        }
    }
    & .image-type {
        display: flex;
        justify-content: flex-end;
        gap: 15px;

        & .math-image-type, .non-math-image-type {
            display: flex;
            align-items: center;
            cursor: pointer;
            gap: 5px;
            & input {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }
    }
    .modal-content {
        .modal-body {
            .preview-image {
                width: 100%;
                height: 100%;
                text-align: left;
                letter-spacing: 0px;
                color: #151515;
                font-size: 20px;
                font-family: 'Poppins-Regular';
                font-weight: 600;

                .close-icon {
                    width: 30px;
                        height: 30px;
                        background-repeat: no-repeat !important;
                        position: absolute;
                        right: 15px;
                        background-image: url("../../assets/images/close-icon.png");
                        cursor: pointer;
                }
            }
            .enlarged-image {
                width: 100%;
            }
        }
    }
    .selectLanguageFilter {
        position: absolute;
        margin: 0 0.7vw 0;
        & .language-filter {
          margin: 0 0.7vw 0;
          & .dropdown-toggle {
            border: none;
            background-color: transparent !important;
            border-radius: 4px;
    
            &::after {
              background-image: url("../../assets/images/lang-dropdown.svg");
              width: 20px;
              height: 14px;
              background-repeat: no-repeat;
              background-position: center center;
              position: relative;
              top: 0.4vw;
            }
    
            &.show {
              &::after {
                transform: rotate(180deg);
              }
            }
            .translateStatus {
              color: #1544e0;
              font-size: 1rem;
              font-family: "Poppins-Regular";
            }
          }
    
          & .dropdown-menu {
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #cdcdcd;
            border-radius: 2px;
            &.show {
            //   left: -70px !important;
            }
            & .dropdown-item {
              text-align: left;
              font-size: 1rem;
              font-family: "Poppins-Regular";
              letter-spacing: 0px;
              color: #151515;
            }
    
            & .dropdown-item.active {
              position: relative;
              background-color: #eef2ff !important;
              color: #151515 !important;
            }
    
            & .dropdown-item {
                .tick-mark {
                    display: none !important;
                    background-image: url("../../assets/images/selectedTick.svg");
                    width: 25px;
                    height: 25px;
                    background-repeat: no-repeat;
                    position: absolute;
                    // display: inline-block !important;
                    right: 10px;
                }
                .selectedLangTick {
                    background-image: url("../../assets/images/selectedTick.svg");
                    width: 25px;
                    height: 25px;
                    background-repeat: no-repeat;
                    position: absolute;
                    right: 10px;
                }
            }
    
            & .dropdown-item:hover {
              background-color: #eef2ff;
            }
          }

          & .language-filter-label {
            font-size: 14px;
            font-family: 'Poppins-Regular';
            letter-spacing: 0px;
            color: #575757;
          }
          & .translateOptions-container {
            display: inline-block;
            margin-left: 8px;
              & .translateOptions {
                width: auto;
                height: 29px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #CDCDCD;
                border-radius: 4px;
                opacity: 1;
                margin-right: 16px;
                color: #575757;
                font-size: 13px;
                font-family: 'Poppins-Regular';
                padding: 0 12px;

                &:hover {
                    background: #EEF2FF 0% 0% no-repeat padding-box;
                    border: 1px solid transparent;
                }
    
                &.active {
                    border: 1px solid #1544E0;
                    color: #1544E0;
                }
                &:focus {
                    outline: none;
                    box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;
                }
              }
          }
        }
      }
}
.alt-text-file-status {
    border-radius: 23px;
    opacity: 1;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins-Regular';
    letter-spacing: 0px;
    color: #1644E1;
    // width: 120px;
    // height: 39px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    cursor: default;
    // &.draft-btn {
    //     background: #EEF2FF 0% 0% no-repeat padding-box;
    //     border: 1px solid #1544E0;
    // }
    // &.reviewed-btn {
    //     background: #E7FFFB 0% 0% no-repeat padding-box;
    //     border: 1px solid #95EEDE;
    // }
}
.custom-loader {
    position: relative;
    margin: 0 auto;
    top: 50%;
    transform: translate(-50%, -50%);
    --d: 22px;
    width: 50px;
    height: 50px;
    background-image: url("../../assets/images/es-loader.gif");
    background-size: contain;
  }
  .details-loader {
    position: absolute;
    margin: 0 auto;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    --d: 22px;
    width: 50px;
    height: 50px;
    background-image: url("../../assets/images/es-loader.gif");
    background-size: contain;
  }
  
  @keyframes s7 {
    100% {transform: rotate(1turn)}
  }

  .personAssignIcon {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    position: absolute;
    right: 5px;
    background-image: url("../../assets/images/person-assigned.svg");
  }

  div#cell-6-undefined {
    cursor: default;
  }
  div[data-column-id="1"] {
    .sc-kpDqfm {
        opacity: 1 !important;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat !important;
        position: absolute;
        background-image: url("../../assets/images/sorting.svg") !important;
        left: 75px;
        top: 21px;
        transform: none !important;
    }
    &.sc-fqkvVR {
        justify-content: center;
    }
    &.rdt_TableCol {
        justify-content: unset;
    }
}
  div[data-column-id="2"] {
    .sc-kpDqfm {
        opacity: 1 !important;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat !important;
        position: absolute;
        background-image: url("../../assets/images/sorting.svg") !important;
        left: 105px;
        top: 21px;
        transform: none !important;
    }
}
  div[data-column-id="4"] {
    // &:hover {
    //     opacity: 1;
    // }
    // &:active {
    //     opacity: 0.7;
    // }
    .sc-kpDqfm {
        opacity: 1 !important;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat !important;
        position: absolute;
        background-image: url("../../assets/images/sorting.svg") !important;
        left: 75px;
        top: 21px;
        transform: none !important;
    }
}

div[data-column-id="6"] {
    // &:hover {
    //     opacity: 1;
    // }
    // &:active {
    //     opacity: 0.7;
    // }
    .sc-kpDqfm {
        opacity: 1 !important;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat !important;
        position: absolute;
        background-image: url("../../assets/images/sorting.svg") !important;
        left: 73px;
        top: 20px;
        transform: none !important;
    }
}
  /* Add this attribute to the element that needs a tooltip */
  [data-tooltip] {
    position: relative;
    z-index: 2;
    cursor: pointer;
  }
  
  /* Hide the tooltip content by default */
  [data-tooltip]:before,
  [data-tooltip]:after {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }
  
  /* Position tooltip above the element */
  [data-tooltip]:before {
    position: absolute;
    bottom: 150%;
    left: 0%;
    margin-bottom: -21px;
    font-size: 13px;
    font-family: 'Poppins-Regular';
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: -120px;
    padding: 10px;
    min-width: 100px;
    width: auto;
    height: auto;
    background-color: #000;
    background-color: #151515;
    content: attr(data-tooltip);
    text-align: center;
    line-height: 1.5;
    border-radius: 14px;
  }
  
  /* Show tooltip content on hover */
  [data-tooltip]:hover:before,
  [data-tooltip]:hover:after {
    visibility: visible;
    opacity: 1;
  }
  .react-datepicker__close-icon:focus-visible {
    box-shadow: 0 0 0 .15rem rgba(13, 110, 253, .761) !important;
    outline: none;
    padding-left: 5px;
    right: 48px;
  }
  .rdt_TableCol_Sortable:focus-visible{
   
        outline: none;
        box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;
   
  }

  .preview-tooltip {
    .tooltip-arrow {
        display: none !important;
    }
}
.sc-bXCLTC{
    &:focus {
        outline: none;
        box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;
    }
}
.sc-koXPp{
    &:focus {
        outline: none;
        box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;
    }
}
.dataTable-header{
    font-size: 16px;
}
.altTextPagePath{
    padding-left: 11px !important;
}
.altTextSlashPath{
    padding-left: 8px;
}