footer {
    width: 87vw;
}
.copyright-text {
    font-size: 0.8rem;
    font-family: 'Poppins-Regular';
    letter-spacing: 0px;
}
.equalsenseFooterLogo , .footerHurixLogo {
    padding-top: 5px;
}
.footerHurixLogo{
    margin-top: 5px;
    width: 100px;
    background-size: 85px 16px;
    height: 22px;
    background-position-y: 4px;
    background-image: url("../../assets/images/HurixDigitalFooterLogo.svg") !important;
    background-repeat: no-repeat;
}
.copyrightLogo{
    gap: 5px;
}