.container-upload {
    top: 0;
    width: 100%;
    padding: 0;

}
.wrapper-upload {
    margin-bottom: 0;
    background-color: #95EEDE;
    align-items: center;
}
.fade-in {
    animation: fadeIn 0.3s 1 forwards;
}
.fade-out {
    animation: fadeOut 0.2s 1 forwards;
}

.error-close-btn {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-image: url("../../assets/images/login_success_clos.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.notification-body-upload {
    & div {
        color: #042520;
        text-align: center;
        font-family: "Poppins-Regular";
        font-weight: 600;
    }
}
.container-error {
    top: 0;
    width: 100%;
    padding: 0;

    & .error-wrapper {
        margin-bottom: 0;
        background-color: #FFE0E0;
        align-items: center;
        & .error-close-btn {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background-image: url("../../assets/images/upload_error.svg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }

        & .notification-error-body {
            & div {
                text-align: center;
                font-family: "Poppins-Regular";
                font-weight: 600;
                color: #9C0000;
            }
        }
    }
}
@keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
  @keyframes fadeIn {
      0% { opacity: 0; }
      100% { opacity: 1; }
    }