header {
    background-color: #151515;
}

.header-navbar {
    width: 94%;

    & .navbar-brand {
        font-family: 'Poppins-Regular';
        font-size: 1.625rem;
        color: #fff;
        font-weight: 600;
        padding: 0;
        cursor: pointer;
        &:focus-visible {
        border-radius :1px;
            box-shadow: 0 0 0 0.15rem #c9ddfb;
            outline: 0; 
         }
        &:hover,
        &:focus {
            color: #fffcff;
        }
        & img{
            width: 210px;
            height: 34px;
            margin: 8px;
        }
    }
}

.navbar-nav {
    & .nav-link {
        color: #fff;
        font-size: 1rem;
        font-size: 16px;
        font-family: 'Poppins-Regular';
        letter-spacing: 0px;
        color: #FFFFFF;
        cursor: pointer;
        &:focus-visible {
            border-radius :1px;
            box-shadow: 0 0 0 0.15rem #c9ddfb;
            outline: 0;
        }

        &.dropdown-toggle {
            &::after {
                position: relative;
                background-image: url('../../assets/images/dropdown_button.svg');
                margin-left: 1.5em;
                border: none;
                width: 14px;
                height: 8px;
                background-repeat: no-repeat;
                background-position: top right;
                background-size: contain;
                top: 0.5em;
                right: 0;
                display: inline-block;
                vertical-align: top;
            }

            .profile {
                display: inline-block;

                & .profile-name {
                    color: #FFFFFF;
                    font: normal normal normal 1.2rem/1.5 Poppins-Medium;
                    letter-spacing: 0px;
                }

                & .email-id {
                    color: #A1A1A1;
                    font: normal normal normal 1rem/1.5 Poppins-Regular;
                    letter-spacing: 0px;
                }

                .user-creds {
                    display: block;
                }
            }
        }

        &:hover,
        &:focus {
            color: #fffcff;
        }
    }
    & .dropdown-item {
        &.change-password {
            color: #4D5154;
            display: flex;
            align-items: center;
            cursor: pointer;
            & img {
                position: relative;
                width: 11px;
                height: auto;
            }
        }
        &.logout {
            color: #9C0000;            
            cursor: pointer;
            display: flex;
            align-items: center;
            & img {
                position: relative;    
                width: 11px;
                height: auto;
            }
            &__text {
                margin-left: 0.5em;
            }
        }
        &:hover {
            background-color: unset;
        }
        .change-password__text {
            margin-left: 0.5em;
        }
        .logout__text {
            margin-left: 0.5em;
        }
    }

   
}

.navbar-toggler {
    background-color: #fff !important;
}
.dropdown-menu {
    &.show {
        font-size: 16px;
        font-family: 'Poppins-Regular';
        letter-spacing: 0px;
        color: #FFFFFF;
    }
}
.skip-main {
    position: absolute;
    opacity: 0;
    text-decoration: none;
    color: #fff;
    background-color: #000;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: default;
    pointer-events: none;
    &:focus{
        opacity: 1;
      }
  }
// .custom-focus:focus-visible {
//     box-shadow: 0 0 0 0.15rem rgb(145, 149, 163) !important;
// }