.left-container {
    background-color:#1544E0;
    background-image: url("../../assets/images/Repeat_Grid_1.svg"), url("../../assets/images/Repeat_Grid_1.svg");
    background-position: right top, left bottom;
    background-repeat: no-repeat, no-repeat;
    padding: 0;
    &__img {
        width: 100%;
        height: 100%;
    }
    &__header-text {
        height: 13%;
    }
    &__parentRow {
        justify-content: center;
        height: 87%;
    }
    &__slider-image {
        height: 75%;
    }
    &__logoWhite {
        padding: 2rem 2rem 1rem !important;
    }
    &__imgLogin {
        text-align: center;
        & img {
            width: 65%;
            text-align: center;
        }
    }
    &__imgAlt {
        background-color: #FFFFFF;
        border-radius: 17px;
        border: medium none;
        box-shadow: 3px 5px 15px #0D2FA3;
        // font: normal normal normal 16px/1.5 Poppins-Regular;
        font: normal normal normal 14px/18px 'Poppins-Regular';
        padding: 1rem 2rem;
        color: #151515;
        text-align: center;

        & .altColor {
            color: #1544E0;
        }
    }
    &__title1 {
        text-align: center;
        font: normal normal 600 1.5rem/1.5 Poppins-Bold;
        letter-spacing: 0px;
        color: #FFFFFF;
        padding-top: 2rem;
    }
    &__desc1 {
        text-align: center;
        font: normal normal normal 16px/25px Poppins-Regular;
        letter-spacing: 0px;
        color: #FCFDFF;
    }
}

.justifyCenter {
    justify-content: center;
}
#loginError,
#logOut-success,
#send_email {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
}
.right-container {
    width: 80%;
    height: 100vh;
    overflow-y: auto;
    &__loginParent {
        margin-left: auto;
        margin-right: auto;
        height: auto;
        padding-top: 12vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__loginForm {
        position: relative;
        font-family: "Poppins-Regular";
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-end;
    }
    &__txtContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
    }
    &__signInTxt {
        display: block;
        font-family: 'Poppins-Bold';
        font-size: 1.625rem;
        color: #151515;
        text-align: center;
    }
    &__subLine {
        display: block;
        font-family: 'Poppins-Regular';
        font-size: 1rem;
        color: #575757;
        text-align: center;
    }
    &__emailField {
        width: 100%;
    }
    &__passwordField {
        width: 100%;
    }
    & form#loginForm {
        width: 100%;
    }
    & #inputEmail,
    #inputPassword {
        color: #151515;
        border: 1px solid #919191;
        border-radius: 10px;
        height: 52px;
        &:focus-visible {
            outline: none;
            box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;
        }
        &::placeholder{
            color: #51585F;
        }
    }
    & #inputPassword {
        padding-right: 45px;
        &:focus-visible {
            outline: none;
            box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;
        }
    }
    &__submitBtn {
        width: 100%;
        height: 52px;
        padding: 0;
        color: #FFFFFF;
        background: #1544E0;
        border-radius: 10px;
        border: none;
        &:hover {
            background: #0E32AB;
        }
        &:focus-visible {
            outline: none;
            box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;
        }
    }
     &__requestBtn {
        width: 100%;
        height: 52px;
        padding: 0;
        color: #FFFFFF;
        background: #1544E0;
        border-radius: 10px;
        border: none;
        &:hover {
            background: #0E32AB;
        }
        &:disabled {
            opacity: .6;
            background: #0E32AB;
        }
    }
    & .forgotPwd {
        color: #1544E0;
        width: 100%;
        text-align: right;
        font-size: 0.8rem;
        margin-bottom: 1.25rem;
        margin-bottom: 2rem;

       a:-webkit-any-link {
        color: #1544E0;
        &:focus-visible {
          outline-offset: 5px;
          outline: none;
          box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;
        }
       }
    }
    & .loginFooter {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        width: 100%;
        padding-bottom: 3vh;
    }
    & .logoImg {
        display: inline-block;
        width: 90px;
        height: 22px;
        background-size: 100%;
        background-image: url("../../assets/images/logo-footer--equalsensebeta.png");
        background-repeat: no-repeat;
        background-color: transparent;
        background-position-y: 7px;
        margin-right: 5px;
    }
    & img.footer-hurix-logo {
        padding-top: 7px;
        padding-left: 5px;
    }
    & .copyrightText {
        color: #575757;
        font-size: 0.8rem;
    }
    & .togglePassword {
        position: relative;
        float: right;
        bottom: 35px;
        & .eye-btn {
            position: absolute;
            width: 25px;
            height: 17.7px;
            right: 12px;
            border: none;
            background-color: transparent;
            // background-size: 100% 100%;
            background-repeat: no-repeat;
            // background-position: top center;
            &.hidden {
                background-image: url("../../assets/images/hidePassword.svg");
            }
            &.visible {
                background-image: url("../../assets/images/showPassword.svg");
                // background-size: contain;
            }
            &:focus-visible {
                    outline: none;
                    box-shadow: 0 0 0 0.15rem #0D6EFDC2 !important;
            }
        }
    }
    & .request-access {
        text-align: center;
    }
    & .request-text {
        font-family: 'Poppins-Regular';
        font-size: 0.85rem;
        line-height: 1.5rem;
    }
    & .request-link {
        text-decoration: none;
        font-family: 'Poppins-Medium';
        color: #1544E0;
    }
    & .ai-product {
        text-align: center;
    }
    & .product-text {
        color: #151515;
        font-family: 'Poppins-Medium';
        font-size: 1rem;
        line-height: 1.5rem;
    }
    & .product-details {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 1rem;
    }
    & .learnquill {
        border: 1px solid #CDCDCD;
        border-radius: 6px;
        padding: 8px 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        column-gap: 1rem;
    }
    & .learnquill-img {
        width: 55%;
    }
    & .learnquill span {
        letter-spacing: 0px;
        color: #1544E0;
        font-family: 'Poppins-Medium';
        font-size: 0.75rem;
        line-height: 1.125rem;
    }
    & .learnquill-link {
        text-decoration: none;
        color: #1544E0;
    }
    & .learnquill span:hover {
        text-decoration: underline;
    }
    & .dictera-img {
        width: 55%;
    }
    & .dictera {
        border: 1px solid #CDCDCD;
        border-radius: 6px;
        padding: 8px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        column-gap: 1rem;
    }
    & .dictera span {
        letter-spacing: 0px;
        color: #1544E0;
        font-family: 'Poppins-Medium';
        font-size: 0.75rem;
        line-height: 1.125rem;
    }
    & .dictera span:hover {
        text-decoration: underline;
    }
    & .footer-logo {
        display: flex;
        align-items: center;
    }
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
// Notification container styling
.Login-error-close-btn {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-image: url("../../assets/images/error_clos_btn.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.Toastify__toast-container {
    &.notification-container,
    &.notification-container-success {
        width: 100%;
        top: -4em;
        position: relative;
    }
}
.Toastify__toast {
    align-items: center;
    &.notification-wrapper-success {
        margin-bottom: 0;
        background-color: #95EEDE;
        align-items: center;
    }
    &.notification-wrapper {
        color:#9C0000;
        background-color: #FFE0E0;
        border: 1px solid #9C0000;
        border-radius: 7px;
        background-image: url("../../assets/images/noun-warning.png");
        background-repeat: no-repeat;
        background-position-x: 1vw;
        background-position-y: center;
        background-size: 5%;
    }
}
.Toastify__toast-body {
    &.notification-body,
    &.notification-body-success {
        font-family: 'Poppins-Regular';
        padding-left: 8%;
    }
}

.Logout-success-close-btn {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-image: url("../../assets/images/login_success_clos.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    &:focus{
        outline: 4px solid white;
    }
}
.notification-body-success {
    & div {
        color: #042520;
        font-family: "Poppins-Regular";
        font-weight: 600;
    }
}

// Preload images 
body::after {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1; // hide images
    content: url("../../assets/images/hidePassword.svg")
    url("../../assets/images/showPassword.svg")
}

@media only screen and (max-width: 768px) {
    .left-container__imgLogin img {
        width: 100%;
    }
    .left-container__logoWhite {
        text-align: center;
    }
    .row .justifyCenter .left-container__imgAlt {
        width: 90%;
    }
    .left-container__img {
        background-image: url(../../assets/images/line-left-login.png);
        background-size: 90% 50%;
        background-repeat: no-repeat;
        background-position: -400% 25%;
        width: 100%;
        height: 100%;
    }
    .left-container__desc1 {
        padding-bottom: 2rem;
    }
    .right-container {
        height: auto;
    }
    .right-container__loginParent {
        height: auto;
    }
    .right-container .loginFooter {
        flex-direction:column-reverse;
    }
    .right-container .loginFooter {
        margin: 2vh 0;
    }
  }

  @media only screen and (min-width: 770px) {
    .left-container__img {
      background-image: url(../../assets/images/line-left-login.png);
      background-size: 90% 43%;
      background-repeat: no-repeat;
      background-position: -378% 32%;
      width: 100%;
      height: 100%;
    }
  }
    @media only screen and (min-width: 1600px) {
        .left-container__img {
        background-image: url(../../assets/images/line-left-login.png);
        background-size: 90% 34%;
        background-repeat: no-repeat;
        background-position: -378% 29%;
        width: 100%;
        height: 100%;
        }
    }
.carousel-container {
    & .carousel-control-prev, .carousel-control-next {
        top: 120px;
        bottom: unset;
        justify-content: unset;
        opacity: 1;
    }
    & .carousel-control-prev {
        & .carousel-control-prev-icon {
            background-image: url("../../assets/images/carousel-default.svg");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            
            &:hover {
                background-image: url("../../assets/images/carousel-nav-hover.svg");
            }
        }
    }
    & .carousel-control-next {
        & .carousel-control-next-icon {
            background-image: url("../../assets/images/carousel-default.svg");
            transform: rotate(180deg);
            background-size: 100% 100%;

            &:hover {
                background-image: url("../../assets/images/carousel-nav-hover.svg");
            }
        }
    }
}
@media (max-width: 992px) and (max-height: 420px) {
    .left-container {
        &__header-text {
            height: auto;
        }
        &__parentRow {
            justify-content: center;
            height: auto;
        }
        &__slider-image {
            height: auto;
        }
        .left-container__desc1 {
            padding-bottom: 2rem;
        }
    }
 }
