.right-container #inputEmail_1 {
    color: #151515;
    border: 1px solid #919191;
    border-radius: 10px;
    height: 52px;
}
.right-container__submitBtn {
    &.disabled {
        pointer-events: none;
    }
}