.pdf-container {
  .loader-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30rem;
    .custom-content-loader {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: conic-gradient(#0000 10%, #766df4);
      -webkit-mask: radial-gradient(
        farthest-side,
        #0000 calc(100% - 8px),
        #000 0
      );
      animation: s3 1s infinite linear;
    }
    @keyframes s3 {
      to {
        transform: rotate(1turn);
      }
    }
  }
  .pdfNavigate {
    display: flex;
    align-items: center;
  }
  .pdfZoom {
    display: flex;
    align-items: center;
  }
  .loader-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30rem;
    .custom-content-loader {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: conic-gradient(#0000 10%, #766df4);
      -webkit-mask: radial-gradient(
        farthest-side,
        #0000 calc(100% - 8px),
        #000 0
      );
      animation: s3 1s infinite linear;
    }
    @keyframes s3 {
      to {
        transform: rotate(1turn);
      }
    }
  }

  & .pdf-header-icon {
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 20px;
    width: 20px;
    margin: 0px 5px 0px 5px;
    border: none;
  }
  & .pageIcon {
    background-image: url("../../assets/images/up-arrow.svg") !important;
  }

  & .prevIcon {
    transform: rotate(180deg);
  }

  & .zoomInIcon {
    background-image: url("../../assets/images/zoom-in.svg") !important;
  }

  & .zoomOutIcon {
    background-image: url("../../assets/images/zoom-out.svg") !important;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e0e0e0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #acacac;
    border-radius: 3px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
