
.right-container form#emailForm {
    width: 100%;
}

.right-container__backToLogin-btn {
    width: 150px;
    height: 28px;
    background-color: transparent;
    background-image: url("../../assets/images/backArrow.png");
    border: none;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 10%;
    color: #1544E0;
}
